import React from 'react';
import StandardPage from '../components/page/page-standard';
import YearsPackageList from '../components/years-packages-list/years-packages-list';
import ArticleTemplate from '../components/article-template/article-template';
import '../components/years-packages-page/years-packages-page.scss';
import items from '../__mocks__/years-packages';
import { lorem } from '../__mocks__/typo';

const pageName = 'years-packages-page';
const parText = `${lorem}\n\n${lorem}`;

const YearsPackagesPage = () => {
  const LIST_CLASS = `${pageName}__list`;
  const ITEM_CLASS = `${pageName}__li`;
  const PACKAGE_CLASS = `${pageName}__package`;
  const BUTTON_WRAP_CLASS = `${pageName}__button-wrap`;
  const ARTICLE_CLASS = `${pageName}__article`;

  return (
    <StandardPage
      pageName={pageName}
      title="Годовые пакеты услуг"
      mainClass="years-packages-page__main"
      backHref="/"
    >
      <YearsPackageList
        className={LIST_CLASS}
        itemClass={ITEM_CLASS}
        packageClass={PACKAGE_CLASS}
        items={items}
        isShowContents
        isShowButton
        buttonWrapClass={BUTTON_WRAP_CLASS}
      />
      <ArticleTemplate
        className={ARTICLE_CLASS}
        title="Условия использования подарочных карт"
      >
        {parText}
      </ArticleTemplate>
    </StandardPage>
  );
};

export default YearsPackagesPage;
